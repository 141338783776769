// 引入 Axios 和 ElementUI
import request from '@/utils/request'
import requestMock from '@/utils/requestMock'
import ElementUI from 'element-ui'

//mock数据使用  requestMock需要换成request 删除此行

// 定义全局变量 $location
const $location = 'https://xyz.macnninc.com/api/'; 


let requestCount = 0; // 记录请求的数量

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // 如果是第一次请求，则显示 loading 效果
    if (requestCount === 0) {
      ElementUI.Loading.service({ fullscreen: true });
    }
    requestCount++; // 请求数量加一
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 如果是第一次请求完成，则关闭 loading 效果
    if (requestCount === 1) {
      ElementUI.Loading.service({ fullscreen: true }).close();
    }
    requestCount--;
    return response;
  },
  (error) => {
    // 处理请求超时和错误
    if (error.response && error.response.status === 400) {
      // 如果是 400 错误，则跳转到首页
      router.push('/');
    }
    // 如果是第一次请求失败，则关闭 loading 效果
    if (requestCount === 1) {
      ElementUI.Loading.service({ fullscreen: true }).close();
    }
    requestCount--;
    // 在响应错误时显示错误提示
    ElementUI.Message.error('Error requesting, please try again.');
    return Promise.reject(error);
  }
);



//全局IP定位查询
export function ipInfo(params){
  return request({
    url: `${$location}frontend/world/get-country-by-ip`,
    method: 'post',
    params ,
  })
}


//首页列表
export function indexList(params){
  return request({
    url: `${$location}frontend/product/home-list`,
    method: 'post',
    params
  })
}


//首页详情
export function detail(params){
  return request({
    url: `${$location}frontend/product/detail`,
    method: 'post',
    params
  })
}



//获取品牌分类
export function brand(params){
  return request({
    url: `${$location}frontend/category/brand-list`,
    method: 'post',
    params
  })
}


//获取品牌分类
export function brandbypid(params){
  return request({
    url: `${$location}frontend/category/get-list-by-parent-id`,
    method: 'post',
    params
  })
}



//购物车价格列表
export function cartprice(params){
  return request({
    url: `${$location}frontend/product/cart-price-list`,
    method: 'post',
    params
  })
}


//确认订单
export function confirmOrder(params){

  return request({
    url: `${$location}frontend/order/place`,
    method: 'post',
    params
  })

}



//下单
export function placeOrder(params){

  return request({
    url: `${$location}frontend/order/confirm`,
    method: 'post',
    params
  })


}


//订单详情
export function orderDetail(params){

  return request({
    url: `${$location}frontend/order/detail`,
    method: 'post',
    params
  })


}



//订单运费主动确认 
export function orderShipHistoryConfirm(params){
  return request({
    url: `${$location}frontend/order/shipConfirm`,
    method: 'post',
    data: params
  })
}



//订单列表
export function orderList(params){

  return request({
    url: `${$location}frontend/order/list`,
    method: 'post',
    params
  })


}



//会员地址列表

export function addressList(params){

  return request({
    url: `${$location}frontend/member-address/list`,
    method: 'post',
    params
  })


}





//删除会员地址

export function adressDelete(params){

  return request({
    url: `${$location}frontend/member-address/delete`,
    method: 'post',
    params
  })


}


//编辑会员地址

export function addressEdit(params){

  return request({
    url: `${$location}frontend/member-address/edit`,
    method: 'post',
    params
  })
}



//添加会员地址
export function addressAdd(params){

  return request({
    url: `${$location}frontend/member-address/add`,
    method: 'post',
    params
  })
}



//获取大洲list
export function worldArea(params){
  return request({
    url: `${$location}frontend/world/get-list-by-continent-id`,
    method: 'post',
    params
  })
}



//获取所有国家list
export function countryList(params){
  return request({
    url: `${$location}frontend/world/get-country-list`,
    method: 'post',
    params
  })
}


//支付
export function pay(params){

  return request({
    url: `${$location}frontend/order/pay`,
    method: 'post',
    params
  })
}



//支付
export function paypalpay(params){

  return request({
    url: `${$location}frontend/order/pay-by-paypal`,
    method: 'post',
    params
  })
}






//获取用户信息详情
export function memberInfo(params){

  return request({
    url: `${$location}frontend/member/detail`,
    method: 'post',
    params
  })
}



//用户编辑
export function memberSave(params){

  return request({
    url: `${$location}frontend/member/edit`,
    method: 'post',
    params
  })
}


//保存密码
export function savePassword(params){

  return request({
    url: `${$location}frontend/member/change-password`,
    method: 'post',
    params
  })
}

//faqlist
export function faqlist(params){

  return request({
    url: `${$location}frontend/article/faq-list`,
    method: 'post',
    params
  })
}


export function question(params){

  return request({
    url: `${$location}frontend/article/add-faq`,
    method: 'post',
    params
  })
}

//单页
export function singlepage(params){

  return request({
    url: `${$location}frontend/single-page/header`,
    method: 'post',
    params
  })
}

//获取已经确认的订单详情
export function orderDetailConfirm(params){
  return request({
    url: `${$location}frontend/order/confirm-detail`,
    method: 'post',
    params
  })
}


//获取已经确认的订单详情
export function alllanmu(params){
  return request({
    url: `${$location}frontend/single-page/all-lanmu`,
    method: 'post',
    params
  })
}



//future
export function futurelist(params){

  return request({
    url: `${$location}frontend/dict/get-plan-list`,
    method: 'post',
    params
  })
}








//future
export function trackByNumber(trackingNumber,courier_code){

  return request({
    url: `${$location}frontend/public/track`,
    method: 'post',
    data: {
      trackingNumber: trackingNumber,
      courier_code: courier_code
    }
  })
}



